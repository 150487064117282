import 'react-native-gesture-handler';
import React, { useState, useEffect } from 'react';
import FtAppInfo from './source/first/ftAppInfo';
import FtNavScreens from './source/first/ftNavScreens';
import appTheme from './source/general/theme';
import Models from './source/models';

import FragHeader                from './source/fragments/fragHeader';
import PageTest                  from './source/pages/pageTest';
import PageAuthLogin             from './source/pages/pageAuthLogin';
import PageMenu                  from './source/pages/pageMenu';
import PageDispositionDashboard  from './source/pages/pageDispositionDashboard';
import PageLeadSearch            from './source/pages/pageLeadSearch';
import PageLeadUpdate            from './source/pages/pageLeadUpdate';

Models.addModelsClassMap();

//********************************************************************
// App: application entry point
export default function App(): JSX.Element
{
    //====================================================================
    // hooks: no conditions here
    const [init, setInit] = useState( false );

    useEffect( (): void => {
        FtAppInfo.staticInit({
            isDebug: false,
            version: '000100100015',
            orien: 'A',
            appId: '76f5a1a73802793ae2b2b76a4f045d206e6da78d304f89a551c5c738772a1bc05cf38171ff17f82ec657c5b048807ea4fbd56ba67f18f62f84f5c0a3cd4d8ce5',
            baseUrlDebug: 'https://localhost:7137/',
            baseUrlRelease: 'https://debtrescuesystemhub.net/',
            title: 'Debt-Rescue Frontend',
        })
        .then( () => setInit( true ) );
    }, []);

    //====================================================================
    // render

    if ( !init ) return (<></>);

    return (<FtNavScreens
        theme={appTheme}
        init='AuthLogin' // AuthLogin LeadSearch
        header={p => <FragHeader {...p} />}
        screens={[
            { name: 'Home'                   , title: 'Home Test'             , comp: p => (<PageTest                     {...p} />) },
            { name: 'AuthLogin'              , title: 'Login'                 , comp: p => (<PageAuthLogin                {...p} />) },
            { name: 'Menu'                   , title: 'Main Menu'             , comp: p => (<PageMenu                     {...p} />) },
            { name: 'DispositionDashboard'   , title: 'Disposition Dashboard' , comp: p => (<PageDispositionDashboard     {...p} />) },
            { name: 'LeadSearch'             , title: 'Leads Search'          , comp: p => (<PageLeadSearch               {...p} />) },
            { name: 'LeadUpdate'             , title: 'Leads Update'          , comp: p => (<PageLeadUpdate               {...p} />) },
        ]}
    />);
}
