import Decimal from 'decimal.js';
import moment from 'moment';
import Ft from '../../first/ft';
import { FtModel } from '../../first/ftTypes';
import FtRapi from '../../first/ftRapi';

//********************************************************************
// DispositionRecBase class : Generated : DO NOT MODIFY
export default class DispositionRecBase implements FtModel 
{
    //====================================================================
    // properties
    id: number;
    category_id: number|null;
    source_id: number|null;
    lead_count: number|null;
    client_count: number|null;
    conversion_rate: Decimal|null;
    revenue: Decimal|null;
    ave_revenue_per_cus: Decimal|null;
    spend: Decimal|null;
    cost_per_action: Decimal|null;
    gross_profit: Decimal|null;

    //====================================================================
    // init

    constructor()
    {
        this.id = 0;
        this.category_id = 0;
        this.source_id = 0;
        this.lead_count = 0;
        this.client_count = 0;
        this.conversion_rate = new Decimal('0.0');
        this.revenue = new Decimal('0.0');
        this.ave_revenue_per_cus = new Decimal('0.0');
        this.spend = new Decimal('0.0');
        this.cost_per_action = new Decimal('0.0');
        this.gross_profit = new Decimal('0.0');
    }

    //====================================================================
    // instance api

    /// decode relevant members of obj to this members
    decodeFromObj( obj: any ): any
    {
        this.id = obj.id;
        this.category_id = obj.category_id;
        this.source_id = obj.source_id;
        this.lead_count = obj.lead_count;
        this.client_count = obj.client_count;
        this.conversion_rate = FtRapi.decodeDec( obj.conversion_rate );
        this.revenue = FtRapi.decodeDec( obj.revenue );
        this.ave_revenue_per_cus = FtRapi.decodeDec( obj.ave_revenue_per_cus );
        this.spend = FtRapi.decodeDec( obj.spend );
        this.cost_per_action = FtRapi.decodeDec( obj.cost_per_action );
        this.gross_profit = FtRapi.decodeDec( obj.gross_profit );
        return this;
    }

    /// encode relevant members of this to obj
    encodeToObj(): any
    {
        const map: any = {
            id : this.id,
            category_id : this.category_id,
            source_id : this.source_id,
            lead_count : this.lead_count,
            client_count : this.client_count,
            conversion_rate : FtRapi.encodeDec( this.conversion_rate ),
            revenue : FtRapi.encodeDec( this.revenue ),
            ave_revenue_per_cus : FtRapi.encodeDec( this.ave_revenue_per_cus ),
            spend : FtRapi.encodeDec( this.spend ),
            cost_per_action : FtRapi.encodeDec( this.cost_per_action ),
            gross_profit : FtRapi.encodeDec( this.gross_profit ),
        };
        return map;
    }

    /// copy members from src object of same type
    copyFrom( src: any ): any
    {
        this.id = Ft.parseInt0( src.id );
        this.category_id = Ft.parseInt0Null( src.category_id );
        this.source_id = Ft.parseInt0Null( src.source_id );
        this.lead_count = Ft.parseInt0Null( src.lead_count );
        this.client_count = Ft.parseInt0Null( src.client_count );
        this.conversion_rate = src.conversion_rate != null ? new Decimal( src.conversion_rate ) : null;
        this.revenue = src.revenue != null ? new Decimal( src.revenue ) : null;
        this.ave_revenue_per_cus = src.ave_revenue_per_cus != null ? new Decimal( src.ave_revenue_per_cus ) : null;
        this.spend = src.spend != null ? new Decimal( src.spend ) : null;
        this.cost_per_action = src.cost_per_action != null ? new Decimal( src.cost_per_action ) : null;
        this.gross_profit = src.gross_profit != null ? new Decimal( src.gross_profit ) : null;
        return this;
    }

    /// clear all members to default values
    clear(): void
    {
        this.id = 0;
        this.category_id = null;
        this.source_id = null;
        this.lead_count = null;
        this.client_count = null;
        this.conversion_rate = null;
        this.revenue = null;
        this.ave_revenue_per_cus = null;
        this.spend = null;
        this.cost_per_action = null;
        this.gross_profit = null;
    }
}
