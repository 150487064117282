import LeadBase from './base/leadBase';

//********************************************************************
// Lead class
// @FTGEN_PROGRESS: busy
export default class Lead extends LeadBase
{
    //====================================================================
    // static data members

    // #FTGEN_CONSTS_A#
    // #FTGEN_CONSTS_Z#

    //====================================================================
    // init
    
    constructor()
    {
        super();
    }
}
