import LeadSourceCategoryBase from './base/leadSourceCategoryBase';

//********************************************************************
// LeadSourceCategory class
// @FTGEN_PROGRESS: busy
export default class LeadSourceCategory extends LeadSourceCategoryBase
{
    //====================================================================
    // static data members

    // #FTGEN_CONSTS_A#
    static LSC_DEBT_RESCUE = 1;
    static LSC_SKULDHULP = 2;
    static LSC_UNKNOWN = 3;
    // #FTGEN_CONSTS_Z#

    //====================================================================
    // init
    
    constructor()
    {
        super();
    }
}
