import FtRapi from './first/ftRapi';
import DispositionRec from './models/dispositionRec';
import GenLookup from './models/genLookup';
import Lead from './models/lead';
import LeadSource from './models/leadSource';
import LeadSourceCategory from './models/leadSourceCategory';
import Parameter from './models/parameter';
import UserAuthRole from './models/userAuthRole';
import UserAuthRoleType from './models/userAuthRoleType';
import UserDevice from './models/userDevice';
import UserType from './models/userType';
import Userz from './models/userz';

//********************************************************************
// Models registry class
export default class Models 
{
    //====================================================================
    // static api

    /// Adds registered model classes to FtRapi.classMap
    /// Use in App():
    ///   Models.addModelsClassMap();
    static addModelsClassMap(): void
    {
        FtRapi.classMap['DispositionRec'] = DispositionRec;
        FtRapi.classMap['GenLookup'] = GenLookup;
        FtRapi.classMap['Lead'] = Lead;
        FtRapi.classMap['LeadSource'] = LeadSource;
        FtRapi.classMap['LeadSourceCategory'] = LeadSourceCategory;
        FtRapi.classMap['Parameter'] = Parameter;
        FtRapi.classMap['UserAuthRole'] = UserAuthRole;
        FtRapi.classMap['UserAuthRoleType'] = UserAuthRoleType;
        FtRapi.classMap['UserDevice'] = UserDevice;
        FtRapi.classMap['UserType'] = UserType;
        FtRapi.classMap['Userz'] = Userz;
    }
}
