import React, { useState, useEffect } from 'react';
import type { FtPageProps, FtDgAction } from '../first/ftTypes';
import Ft from '../first/ft';
import FtRapi from '../first/ftRapi';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtCon from '../first/ftCon';
import FtSpacer from '../first/ftSpacer';
import FtForm from '../first/ftForm';
import FtFmSubmit from '../first/ftFmSubmit';
import FtFmTextField from '../first/ftFmTextField';
import FtFmDateField from '../first/ftFmDateField';
import FtFmDropdownField from '../first/ftFmDropdownField';
import FtDataGrid from '../first/ftDataGrid';
import { ftBusy } from '../first/ftBusy';
import { ftPopupMessage } from '../first/ftPopupMessage';
import Th from '../general/th';

import GenLookup                          from '../models/genLookup';
import Lead                               from '../models/lead';

//********************************************************************
// PageLeadSearch
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PageLeadSearch( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here
    
    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const [ popup                  , setPopup                  ] = useState( <></> ); // placeholder for any popup message/options
    const [ busy                   , setBusy                   ] = useState( false );
    const [ mdlLead                , setMdlLead                ] = useState<Lead|null>( new Lead() );
    const [ lkpSource              , setLkpSource              ] = useState<GenLookup[]>( [] );
    const [ lstLead                , setLstLead                ] = useState<Lead[]>( [] );

    //- hooks: effect/startup -----
    useEffect( onLoad, [] );


    //====================================================================
    // render
    
    if ( busy || Ft.eAny( lkpSource ) ) return ftBusy( { popup: popup } );

    return (<FtCanvas {...Th.page}>
        {popup}

        <FtCon ss='12|12|12|9' md='rw' lt='z,a' marB={15}>
        </FtCon>
                
        <FtFrame text='Search Parameters' {...Th.frame}>
            <FtForm {...Th.form}
                models={{ m: mdlLead }}
                onSubmit={search}
            >
                <FtFmDateField     name='m.create_dttm'   label='Created Date'  {...Th.field} />
                <FtFmTextField     name='m.email'         label='Email'         {...Th.field} />
                <FtFmTextField     name='m.phone'         label='Phone'         {...Th.field} />
                <FtFmDropdownField name='m.source_id'     label='Source'        {...Th.field} data={lkpSource} />
                <FtSpacer />
                <FtFmSubmit ss='3:6:3|9:3' label='Search' {...Th.button} />
                <FtSpacer />
            </FtForm>
        </FtFrame>

        <FtFrame text='Search Results' {...Th.frame} ss='12'>
            <FtDataGrid {...Th.grid} pgsize={50}
                data={lstLead}
                cols={[
                    { fld: ''                                   , hd: 'Action'                             , type: 'act'  , flex: 1,  fmt: 'mc-pencil', clr: '#808080', act: onTapLeadUpdate },
                    { fld: 'create_dttm'                        , hd: 'Created'                            , type: 'dtm'  , flex: 3, fmt: 'YYYY/MM/DD HH:mm' },
                    { fld: 'email'                              , hd: 'Email'                              , type: 'txt'  , flex: 3 },
                    { fld: 'phone'                              , hd: 'Phone'                              , type: 'txt'  , flex: 3 },
                    { fld: 'is_joint'                           , hd: 'Is Joint'                           , type: 'bool' , flex: 1, clr: '#808080' },
                    { fld: 'form_16_date'                       , hd: 'Form 16 Date'                       , type: 'dtm'  , flex: 3, fmt: 'YYYY/MM/DD HH:mm' },
                    { fld: 'repayment_amount'                   , hd: 'Repayment Amount'                   , type: 'dec'  , flex: 2, fmt: 2 },
                    { fld: 'source_id'                          , hd: 'Source'                             , type: 'lkp'  , flex: 3, lkp: lkpSource },
                ]}
            />
        </FtFrame>

    </FtCanvas>);

    //====================================================================
    // event handlers

    function onLoad(): void 
    {
        FtRapi.callList( 'leadSourceLookup', {
            success: ( result, data ) => setLkpSource( data[0] ),
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: nav.pop } ),
        });
    }

    function search(): void
    {
        setBusy( true );
        FtRapi.callList( 'leadSearch', {
            urlParms: {
                'create_dttm' : mdlLead?.create_dttm != null ? Ft.dtFmt( mdlLead?.create_dttm ) : null,
                'email'       : mdlLead?.email,
                'phone'       : mdlLead?.phone,
                'source_id'   : mdlLead?.source_id,
            },
            success: ( result, data ) => setLstLead( data[0] ),
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: () => setLstLead( [] as Lead[] ) } ),
            complete: () => setBusy( false ),
        });
    }

    function onTapLeadUpdate( { dataId }: FtDgAction ): void
    {
        nav.push( 'LeadUpdate', { leadId: dataId } );
    }

}
